<template>
  <div class="quality-box">
    <!--  引入头部-->
    <Head carouseType="QUALITY"></Head>
    <div class="quality-body">
      <div class="quality-body-title">
        <div class="title">{{ $store.state.staticName.quality_title }}</div>
        <div class="xian"></div>
      </div>

      <div class="quality-list">
        <div class="quality-title-list-box">
          <div class="quality-title-list">
            <swiper
              class="swiper-container"
              ref="mySwiper"
              :options="swiperOptions"
            >
              <swiper-slide
                class="swiper-slide"
                :style="swiperStyle"
                v-for="(item, i) in qualityTitleList"
                :key="'title' + i"
              >
                <div
                  class="quality-title-item"
                  @click="handleDetail(item.id)"
                  :style="
                    item.id === active ? 'border-bottom: 4px solid #1850af' : ''
                  "
                >
                  {{ item.title }}
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="quality-detail">
          <div class="img-box" v-if="quality.abbreviation !== undefined">
            <img :src="quality.abbreviation[0]" />
          </div>
          <div class="detail-text-box">
            <div class="detail-title">
              {{ quality.title }}
            </div>
            <div class="detail-text">
              {{ quality.text }}
            </div>
          </div>
        </div>
      </div>
      <div class="quality-body-title">
        <div class="title">{{ $store.state.staticName.quality }}</div>
        <div class="xian"></div>
      </div>
      <div class="quality-guarantee-box">
        <div class="title">
          {{ detailObj.title }}
        </div>
        <div class="quality-guarantee-img-box">
          <div
            class="img-box"
            v-for="(item, i) in detailObj.abbreviation"
            :key="i"
          >
            <!--            <el-image :src="item"></el-image>-->
            <el-image
              class="img-box"
              :src="item"
              style="margin-top: 0"
              :preview-src-list="[item]"
            ></el-image>
          </div>
        </div>
        <div class="subHead">
          {{ detailObj.subhead }}
        </div>
        <div class="sub-quality-guarantee-img-box">
          <div
            class="img-box"
            v-for="(item, i) in detailObj.subAbbreviation"
            :key="i"
          >
            <el-image
              class="img-box"
              :src="item"
              style="margin-top: 0"
              :preview-src-list="[item]"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
    <!--  引入脚部-->
    <Foot></Foot>
  </div>
</template>

<script>
import Head from "@/components/Head";
import Foot from "@/components/Foot";
import { mapState } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

export default {
  name: "quality",
  components: { Foot, Head, Swiper, SwiperSlide },
  data() {
    return {
      detailObj: [],
      // 标题导航栏数组
      qualityTitleList: [],
      // 选中标题
      active: 0,
      quality: {},
      swiperStyle: {},
      swiperOptions: {
        slidesPerView: "auto",
        freeMode: true, // 随意拖动位置
        mousewheel: true,
      },
      stepActive: 6,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    ...mapState({
      languageType: (state) => state.languageType, // 语言类型
    }),
  },
  watch: {
    "$route.query.id": {
      handler(newVal) {
        this.reFresh();
        // this.fenleId = newVal
      },
    },
    "$store.state.languageType": {
      handler(newVal) {
        this.queryTitleList();
        this.getData();
      },
      // deep: true // 深度监听
    },
    stepActive: {
      handler(newVal, oldVal) {
        if (this.stepActive) {
          this.swiperStyle = "width:" + (500 * this.stepActive) / 16 + "rem"; // 动态设置宽度
        }
      },
      immediate: false,
    },
  },
  destroyed() {
    // this.swiper.destroy(false);
  },
  created() {
    this.reFresh();
  },
  methods: {
    reFresh() {
      // this.active = this.$route.params.id
      this.queryTitleList();
      this.getData();
    },
    queryTitleList() {
      this.$axios({
        url: "/queryPRO_ProductTitle",
        method: "GET",
        params: {
          type: this.languageType,
        },
      }).then((res) => {
        this.qualityTitleList = res.data.value;

        if (this.qualityTitleList.length > 0) {
          if (this.$route.query.id) {
            this.handleDetail(parseInt(this.$route.query.id));
          } else {
            let id = this.qualityTitleList[0].id;
            this.handleDetail(id);
          }
        }
      });
    },
    handleDetail(id) {
      this.active = id;
      this.$axios({
        url: "/queryPRO_Product",
        method: "GET",
        params: {
          type: this.languageType,
          id: id,
        },
      }).then((res) => {
        this.quality = res.data.value;
      });
    },
    getData() {
      this.$axios({
        url: "/queryPRO_Quality",
        method: "GET",
        params: {
          type: this.languageType,
        },
      }).then((res) => {
        this.detailObj = res.data.value ? res.data.value : [];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  display: flex;
  width: unset;
  height: unset;
}

.SegmentCell {
  padding: 20px 0;
  border: solid 1px red;

  .swiper-container {
    .swiper-wrapper {
      transition: all 0.5s;
    }

    // 连接线 - 不跟随内容滚动，放到.swiper-slide里面则跟随内容滚动
    &:before {
      content: "";
      position: absolute;
      top: 28px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: red;
      opacity: 0.2;
    }

    .swiper-slide {
      width: 200%;
      height: 100px;

      .item {
        display: inline-block;
        width: 500px;
        color: red;
      }
    }
  }
}

.quality-box {
  background: #ffffff;

  .quality-body {
    .quality-body-title {
      margin: {
        top: 74px;
        left: 130px;
        right: 130px;
      }
      width: 1660px;
      height: 90px;

      .title {
        width: 1660px;
        height: 70px;
        font-size: 50px;
        font-weight: 400;
        text-align: center;
        color: #4d4d4d;
      }

      .xian {
        margin-top: 8px;
        border: 1px solid #808080;
      }
    }

    .quality-list {
      .quality-title-list-box {
        width: 1660px;
        height: 86px;
        background: #f2f2f2;
        margin-left: 130px;
        margin-right: 130px;

        .quality-title-list {
          user-select: none;
          height: 86px;
          display: flex;
          align-items: center;

          .quality-title-item {
            margin-left: 80px;
            margin-right: 80px;
            min-width: 140px;
            height: 42px;
            font-size: 30px;
            font-weight: 400;
            text-align: center;
            color: #333333;
            cursor: pointer;
          }
        }
      }

      .quality-detail {
        width: 1660px;
        height: 540px;
        background: #fcfcfc;
        display: flex;
        margin-bottom: 105px;
        margin-left: 130px;

        .img-box {
          width: 798px;
          height: 540px;
          background: rgba(0, 0, 0, 0.1);

          img {
            width: 793px;
            height: 540px;
          }
        }

        .detail-text-box {
          width: 794px;
          height: 540px;
          margin-left: 32px;

          .detail-title {
            margin-top: 32px;
            width: 794px;
            height: 56px;
            text-align: center;
            font-size: 40px;
            font-weight: 400;
            color: #4d4d4d;
          }

          .detail-text {
            margin-top: 32px;
            width: 794px;
            height: 429px;
            text-indent: 2em;
            word-break: break-all;
            display: -webkit-box; /**对象作为伸缩盒子模型展示**/
            -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
            -webkit-line-clamp: 7; /**显示的行数**/
            overflow: hidden; /**隐藏超出的内容**/
            font-size: 20px;
            font-weight: 400;
            text-align: left;
            color: #666666;
          }
        }
      }
    }
    .quality-guarantee-box {
      width: 1660px;
      margin-left: 130px;
      .title {
        margin-top: 21px;
        font-size: 20px;
        font-weight: 300;
        text-align: left;
        color: #4d4d4d;
      }
      .quality-guarantee-img-box {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        .img-box {
          width: 330px;
          height: 260px;
          background: #f2f2f2;
          margin-top: 66px;
        }
      }
      .subHead {
        width: 1660px;
        margin-top: 113px;
        font-size: 50px;
        font-weight: Normal;
        text-align: center;
        color: #4d4d4d;
      }
      .sub-quality-guarantee-img-box {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        margin-bottom: 79px;
        .img-box {
          width: 330px;
          height: 420px;
          background: #f2f2f2;
          margin-top: 66px;
        }
      }
    }
  }
}
:deep(.el-carousel__container),
my-carousel {
  height: 580px !important;
}
</style>
